<template>
    <div class="container">
        <b-row>
            <b-col cols="12" sm="6" class="mb-4 mt-3">
                <h1>Hareketler</h1>
            </b-col>
            <b-col cols="12" sm="6" class="text-right mt-2 mb-3">
                <date-picker 
                    v-model="range" 
                    type="date" 
                    format="YYYY-MM-DD" 
                    value-type="YYYY-MM-DD"
                    placeholder="Tarih Seçin"
                    range-separator=" / "
                    confirm 
                    range 
                    class="datePicker"
                    ref="datePicker"
                    confirm-text='Tamam'></date-picker>
            </b-col>
        </b-row>
        <b-col>
            <b-row>
                <b-table v-if="atik_alimlari" responsive striped hover :fields="fields" :items="atik_alimlari"></b-table>
                <div v-else>
                    <p>Sistemde kayıtlı veri bulunamadı!</p>
                </div>
            </b-row>
        </b-col>
    </div>
</template>

<script>
export default {
    data(){
        return{
            fields : [
                {
                    "key" : "firma_unvan",
                    "label" : "Firma",
                    "sortable": "true"
                },
                {
                    "key" : "user_adsoyad",
                    "label" : "Kullanıcı",
                    "thStyle" : {width: '250px'},
                    "sortable": "true"
                },
                {
                    "key" : "atik_alim_miktar",
                    "label" : "Miktar",
                    "thStyle" : {width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "atik_ad",
                    "label" : "Atık Türü",
                    "thStyle" : {width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "atik_alim_tarih",
                    "label" : "Tarih",
                    "thStyle" : {width: '200px'},
                    "sortable": "true"
                },
            ],
            rangeChange: null,
        }
    },

    computed : {
        atik_alimlari(){
            if(this.$store.state.atik_alimlari){
                if(!this.$route.params.id){
                    return this.$store.state.atik_alimlari;
                }else{
                    return this.$store.state.atik_alimlari.filter(c => c.atik_alim_user==this.$route.params.id)
                }
            }else{
                return null;
            }
        },
        user(){
            return this.$store.state.user;
        },
        days(){
            return this.$store.state.days;
        },
        range: {
            get(){
                return this.rangeChange === null ? [this.days.start, this.days.finish] : this.rangeChange;
            },
            set(val){
                this.rangeChange = val        	
            }
        }
    },

    methods : {
        onayToggle(ndx){
            var b = this.atik_alimlari[ndx].atik_alim_durum;
            var id = this.atik_alimlari[ndx].atik_alim_id;
            const durum = b == 2 ? 1 : 2;
            this.$store.dispatch("atikAlimEdit", {id: id, durum : durum});
            this.atik_alimlari[ndx].atik_alim_durum = durum;
        }
    },

    watch: {
        rangeChange(a){
            var deger = {
                start: a[0],
                finish: a[1],
            }
            this.veriGuncelle(deger);
        }
    }
}
</script>


<style scoped>
.badge:hover{
    cursor: pointer;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
@media (max-width: 767px){
  .datePicker{
    width: 100%;
  }
}
</style>